import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import styled from "styled-components"
import Layout from "../components/layout"
import BlogList from "../components/blog/BlogList"
import SEO from "../components/seo"
import { Container, Heading, Text } from "../components/styled/Styled"
import { MEDIA_QUERIES, COLORS } from "../constants"

const ImgContainer = styled(motion.div)`
  width: 40%;
  z-index: -2;
  padding-top: 2rem;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 90%;
    margin: auto;
    padding-top: 2rem;
  }
`

const Section = styled(motion.div)`
  display: flex;
  width: 100%;
  border-right: 8px solid ${COLORS.BLACK};
  flex-wrap: wrap;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column-reverse;
    border-right: none;
    margin: auto;
  }
`

const Article = styled(motion.div)`
  display: flex;
  width: 60%;
  flex-direction: column;
  padding: 0 3rem;
  text-align: right;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column;
    width: 100%;
    margin: auto;
    padding: 0 1rem;
    text-align: center;
  }
`
const StyledSubheading = styled(motion.div)`
  margin-right: 1rem;
  text-align: right;
  width: 100%;
  color: ${COLORS.PRIMARY};
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    display: none;
  }
`

const headingVar = {
  initial: { opacity: 0, x: "-300px" },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 2,
      delay: 1,
      type: "spring",
      stiffness: 40,
    },
  },
}

const textVar = {
  initial: { opacity: 0, x: "-300px" },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 2,
      delay: 1.2,
      type: "spring",
      stiffness: 40,
    },
  },
}

const imgVar = {
  initial: { opacity: 0, y: "-300px" },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 2, type: "spring", stiffness: 40 },
  },
}

const articleVar = {
  initial: { opacity: 0, y: "-300px" },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 2, type: "spring", stiffness: 40 },
  },
}

const sectionVar = {
  initial: { opacity: 0, y: "300px" },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 2, type: "spring", stiffness: 40 },
  },
}

const BlogPage = () => {
  const { banner } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "anke-profile-5.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Blog" />

      <Container>
        <Section>
          <StyledSubheading>BLOG</StyledSubheading>
          <Article initial="initial" animate="animate" variants={articleVar}>
            <Heading initial="initial" animate="animate" variants={headingVar}>
              RECENT BLOG POSTS
            </Heading>
            <Text initial="initial" animate="animate" variants={textVar}>
              Ut enim ad minima veniam, quis nostrum exercitationem ullam
              corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
              consequatur? Quis autem vel eum iure reprehenderit qui in ea
              voluptate velit esse quam nihil molestiae consequatur, vel illum
              qui dolorem eum fugiat quo voluptas nulla pariatur. Ut enim ad
              minima veniam, quis nostrum exercitationem ullam corporis suscipit
              laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem
              vel eum iure reprehenderit qui in ea voluptate velit esse quam
              nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo
              voluptas nulla pariatur.
            </Text>
          </Article>
          <ImgContainer
            initial="initial"
            animate="animate"
            variants={imgVar}
            style={{ marginTop: "auto" }}
          >
            <Img fluid={banner.childImageSharp.fluid} />
          </ImgContainer>
        </Section>
        <Section style={{ paddingTop: "4rem" }}>
          <motion.div
            style={{ width: "100%" }}
            initial="initial"
            animate="animate"
            variants={sectionVar}
          >
            <BlogList />
          </motion.div>
        </Section>
      </Container>
    </Layout>
  )
}

export default BlogPage
