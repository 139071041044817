import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { InternalLink, Text, Subheading } from "../styled/Styled"
import { MEDIA_QUERIES } from "../../constants"

const Blog = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: start;
  width: 100%;
`

const BlogItem = styled.div`
  position: relative;
  display: flex;
  width: 48%;
  margin: 0 1% 1rem 1%;
  background-color: white;
  z-index: 5;
  box-shadow: 7px 10px 19px -13px rgba(0, 0, 0, 0.75);

  @media (max-width: ${MEDIA_QUERIES.LARGE}) {
    width: 100%;
  }

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    flex-direction: column;
  }
`

const BlogImage = styled(Image)`
  width: 50%;

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    width: 100%;
    height: 150px;
  }
`

const Article = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 3%;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 100%;
  }
`

const BlogList = ({ limit = 3 }) => {
  const {
    allMarkdownRemark: { nodes: blogPosts },
  } = useStaticQuery(graphql`
    query BlogQuery {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "//blog//" } }) {
        nodes {
          rawMarkdownBody
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            featuredimage {
              childImageSharp {
                fluid(quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Blog>
      {blogPosts &&
        blogPosts
          .slice(0, limit)
          .map(({ fields: { slug }, frontmatter: blogPost }, i) => {
            const excerpt =
              blogPost.excerpt.length >= 180
                ? `${blogPost.excerpt.substring(0, 180)}...`
                : blogPost.excerpt
            return (
              <BlogItem key={i}>
                <BlogImage
                  fluid={blogPost.featuredimage.childImageSharp.fluid}
                />
                <Article>
                  <Subheading style={{ marginTop: "1rem" }}>
                    {blogPost.title}
                  </Subheading>
                  <Text>{excerpt}</Text>
                  <InternalLink
                    style={{
                      marginLeft: "auto",
                      marginTop: "auto",
                      marginBottom: "1rem",
                    }}
                    to={slug}
                  >
                    Read more
                  </InternalLink>
                </Article>
              </BlogItem>
            )
          })}
    </Blog>
  )
}

BlogList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default BlogList
